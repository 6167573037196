* {
  /* color: rgb(212, 116, 37); */
  color: white;
  margin: 0;
  padding: 0;
}

body {
  background-color: rgb(3, 3, 24);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  background-color: darkcyan;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 0rem 0.2rem 0.1rem rgb(255, 255, 255);
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.yeller {
  margin-left: 1rem;
  text-shadow: 0rem 0rem 1rem black;
}

.li-design {
  font-style: italic;
  font-size: 1.18rem;
  font-weight: 700;
  border-radius: 1rem;
  background-color: rgb(33, 44, 48);
  padding: 0.5rem 2rem;
  text-align: center;
  margin-left: 2rem;
}
.li-design:hover {
  box-shadow: 0 0 0.5rem rgb(255, 255, 255);
}
.li-click{
  box-shadow: 0 0 0.5rem rgb(255, 255, 255);  
}

.nav-flexgrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 1rem;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
  margin: 1rem 1rem;
}

.about-article {
  border-radius: 0.7rem;
  box-shadow: 0 0.5rem 0.5rem rgb(255, 255, 255);
  padding: 1rem;
  margin: 1rem 1rem;
}

.about {
  text-align: center;
  border-bottom: solid 1px rgb(255, 167, 2);
}

.myskill {
  border-bottom: solid 1px rgb(255, 167, 2);
  margin-bottom: 1rem;
}

.profile-pic {
  width: 20rem;
  height: 23rem;
  border-radius: 1rem;
}

.bio {
  width: 50rem;
  text-align: left;
}

.port-article {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
  margin: 1rem 1rem;
}

.project-section {
  border-radius: 0.7rem;
  box-shadow: 0 0.5rem 0.5rem rgb(255, 255, 255);
  padding: 1rem;
  margin: 1rem 1rem;
}

.project-photo {
  width: 39rem;
  margin-right: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgb(255, 255, 255);
}

.project-title {
  border-bottom: solid 1px rgb(255, 167, 2);
  margin-left: 1rem;
}

.contact-article {
  text-align: center;
  border-radius: 0.7rem;
  box-shadow: 0 0.5rem 0.5rem rgb(255, 255, 255);
  padding: 1rem;
  margin: 1rem 1rem;
  display: flex;
  flex-wrap: wrap;
}

.contact-article h2 {
  margin-bottom: 1rem;
}

.col-2 {
  display: grid;
  grid-template-columns: 10rem 15rem;
  grid-template-rows: 2rem;
  margin-bottom: 1rem;
}

.col-message {
  display: grid;
  grid-template-columns: 10rem 15rem;
  grid-template-rows: 8rem;
}

.form-input {
  color: black !important;
  font-weight: 700;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-radius: 0.5rem;
}

.contact-button {
  width: 10rem;
  margin: 1rem 0rem 0rem 5rem;
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: rgb(33, 44, 48);
}

.error-text {
  margin: 1rem 0rem 0rem 5rem;
}

.resume-section {
  text-align: center;
  border-radius: 0.7rem;
  box-shadow: 0 0.5rem 0.5rem rgb(255, 255, 255);
  padding: 1rem;
  margin: 1rem 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.resume {
  width: 50rem;
  height: 50rem;
}

.skills li {
  margin-bottom: 2rem;
  font-style: italic;
  font-weight: 700;
  border-bottom: solid 1px rgb(255, 167, 2);
}

.li-footerdesign {
  text-align: center;
  margin-left: 2rem;
}

.sociallinks {
  width: 3rem;
}

.github-live {
  width: 4rem;
}

.linkedin {
  width: 3.7rem;
}

.youtube {
  width: 3.7rem;
}

.port-flex-row{
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-evenly;
  margin: 1rem 1rem;
  margin-top: -18.8rem;
  padding: 7rem 0rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
  visibility: hidden;
}

.div-project:hover .port-flex-row {
  visibility: visible;
  opacity: 0.7;
}

.resume-button {
  width: 10rem;
  margin: 1rem;
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: rgb(33, 44, 48);
}

.download {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1599px) {
  .project-photo {
    margin: 1rem 0 0 0;
  }

  .resume {
    width: 30rem;
    height: 30rem;
  }
}
@media screen and (max-width: 753px) {
  .li-design {
    font-size: 1.18rem;
    font-weight: 700;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    text-align: center;
    margin-left: 1rem;
  }

  .project-photo {
    width: 20rem;
    margin: 1rem 0 0 0;
  }

  .github-live {
    width: 3rem;
  }

  .port-flex-row {
    margin-top: -9.75rem;
    padding: 3rem 0rem;
  }

}



@media screen and (max-width: 577px) {
  
  .nav-flexgrid {
    margin: 1rem 2rem;
    padding-right: 1rem;
    display: grid;
    grid-template-columns: 8rem 8rem;
    grid-template-rows: 4rem 4rem;
    row-gap: 1rem;
    column-gap: 1rem;
  }

  .li-design {
    font-size: 1.18rem;
    font-weight: 600;
    padding: 1.2rem;
    margin: 0;
  }

  .port-article {
    margin: 0;
  }

  .about-article {
    padding: 0rem;
    margin: 1rem 1rem;
  }

  .bio {
    margin: 1rem 0rem;
  }

  .project-photo {
    width: 18rem;
    margin: 0;
  }

  .col-2 {
    display: grid;
    grid-template-columns: 5rem 12rem;
    grid-template-rows: 2rem;
    margin-bottom: 1rem;
  }
  
  .col-message {
    display: grid;
    grid-template-columns: 5rem 12rem;
    grid-template-rows: 8rem;
  }

  .resume {
    width: 19rem;
  }

  .li-footerdesign {
    text-align: center;
    margin-left: 2rem;
  }

  .github-live {
    width: 2.5rem;
  }

  .port-flex-row {
    margin-top: -9rem;
    padding: 3rem 0rem;
  }
}